import React, { useCallback, useEffect, useState } from 'react';

import { EngagementSpotlight, useCoordination } from '@atlassiansox/engagekit-ts';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import { PrimaryButton, type PrimaryButtonProps } from '@atlaskit/atlassian-navigation';
import { type ButtonProps } from '@atlaskit/button';
import { RovoIcon } from '@atlaskit/logo';
import { SpotlightManager, SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';
import { useAnalytics } from '@atlassian/conversation-assistant-instrumentation';
import {
	ConversationAssistantOnboardingSpotlight,
	SPOTLIGHT_ONBOARDING_TARGET,
	useConversationAssistantSpotlightActions,
	useConversationAssistantSpotlightState,
	useCoordinationClient,
} from '@atlassian/conversation-assistant-onboarding';
import { TopNavButton } from '@atlassian/navigation-system/experimental/top-nav-button';

import { messages } from './messages';

type Props = {
	testId?: string;
	isActive: boolean;
	isDisabled?: boolean;
	onClick: ButtonProps['onClick'];
	tooltipContent?: React.ReactNode;
	siteId: string;
	productKey: string;
};

const defaultButtonStyles = {
	paddingInline: '8px',
};

const inactiveButtonStyles = {
	background: token('color.background.neutral'),
};

const disabledButtonStyles = {
	background: token('color.background.disabled'),
	color: token('color.text.disabled'),
};

type ConditionalWrapperChildren = React.PropsWithChildren<any> | null;

export const ConditionalWrapper = ({
	condition,
	wrapper,
	children,
}: {
	condition: boolean;
	wrapper: (children: ConditionalWrapperChildren) => ConditionalWrapperChildren;
	children: ConditionalWrapperChildren;
}) => (condition ? wrapper(children) : children);

type ButtonComponentProps = {
	onMouseEnter?: React.MouseEventHandler;
	style?: React.CSSProperties;
	iconBefore?: React.ReactChild;
	testId?: string;
	isSelected?: boolean;
	onClick?: PrimaryButtonProps['onClick'];
	isDisabled?: boolean;
	tooltip?: React.ReactNode;
	children: React.ReactNode;
};

const getConversationAssistantButton =
	(Button: React.ComponentType<ButtonComponentProps> = PrimaryButton) =>
	({
		testId,
		isActive,
		isDisabled = false,
		onClick: onClickProp,
		tooltipContent,
		siteId,
		productKey,
	}: Props) => {
		di(useCoordination, useCoordinationClient);
		const { formatMessage } = useIntl();
		const { start } = useConversationAssistantSpotlightActions();
		const [shouldShowMessage, setShouldShowMessage] = useState(false);
		const messageId =
			productKey === 'confluence'
				? 'confluence_rovo_chat_agents_spotlight_ga'
				: productKey === 'jira'
					? 'jira_rovo_chat_agents_spotlight_ga'
					: '';
		const spotlightOnboardingFlagEnabled = fg('rovo_chat_agents_spotlight_onboarding');
		const shouldUseCoordination = spotlightOnboardingFlagEnabled && !!messageId;
		const { sendAnalyticsEvent } = useAnalytics();

		const openRovoChat = (
			event: React.MouseEvent<HTMLElement, MouseEvent>,
			analyticsEvent: any,
		) => {
			if (!isActive && onClick) {
				onClick(event, analyticsEvent);
			}
		};

		const onClick = useCallback<NonNullable<typeof onClickProp>>(
			(...params) => {
				sendAnalyticsEvent({
					eventType: 'ui',
					action: 'clicked',
					actionSubject: 'rovoChatButton',
					attributes: {
						isActive,
						isDisabled,
						productKey,
					},
				});

				return onClickProp?.(...params);
			},
			[isActive, isDisabled, onClickProp, productKey, sendAnalyticsEvent],
		);

		const children = (
			<Button
				onMouseEnter={() => {
					if (shouldShowMessage) {
						sendAnalyticsEvent({
							eventType: 'ui',
							action: 'hovered',
							actionSubject: 'rovoChatButtonSpotlight',
						});
						start();
					}
				}}
				style={{
					/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
					...defaultButtonStyles,
					...(!isActive && inactiveButtonStyles),
					...(isDisabled && disabledButtonStyles),
					/* eslint-enable @atlaskit/ui-styling-standard/enforce-style-prop */
				}}
				iconBefore={<RovoIcon size="xsmall" label="" />}
				testId={testId}
				isSelected={isActive}
				onClick={onClick}
				isDisabled={isDisabled}
				tooltip={tooltipContent ?? formatMessage(messages.buttonLabel)}
			>
				{formatMessage(messages.buttonLabel)}
			</Button>
		);

		return shouldUseCoordination ? (
			<SpotlightWrapper
				setShouldShowMessage={setShouldShowMessage}
				messageId={messageId}
				siteId={siteId}
				children={children}
				shouldShowMessage={shouldShowMessage}
				openRovoChat={openRovoChat}
			/>
		) : (
			children
		);
	};

export const ConversationAssistantButton = getConversationAssistantButton(PrimaryButton);
export const ConversationAssistantButtonNav4 = getConversationAssistantButton(function Nav4Button(
	props: ButtonComponentProps,
) {
	return <TopNavButton {...props} iconBefore={() => <RovoIcon size="xsmall" label="" />} />;
});

export const SpotlightWrapper = ({
	children,
	messageId,
	siteId,
	setShouldShowMessage,
	shouldShowMessage,
	openRovoChat,
}: {
	children: ConditionalWrapperChildren;
	messageId: string;
	siteId: string;
	setShouldShowMessage: React.Dispatch<React.SetStateAction<boolean>>;
	shouldShowMessage: boolean;
	openRovoChat: (event: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: any) => void;
}) => {
	const { activeSpotlight } = useConversationAssistantSpotlightState();
	const coordinationClient = useCoordinationClient(siteId);
	const [onboardingStarted, stopOnboarding] = useCoordination(coordinationClient, messageId);
	const { sendAnalyticsEvent } = useAnalytics();
	const useBlanketTint = fg('rovo_chat_agents_spotlight_use_blanket_tint');

	const onDismissMessage = () => {
		setShouldShowMessage(false);
		stopOnboarding();
	};

	useEffect(() => {
		if (shouldShowMessage && activeSpotlight !== null) {
			sendAnalyticsEvent({
				eventType: 'ui',
				action: 'shown',
				actionSubject: 'rovoChatButtonSpotlight',
			});
		}
	}, [shouldShowMessage, activeSpotlight, sendAnalyticsEvent]);

	useEffect(() => {
		if (onboardingStarted) {
			setShouldShowMessage(true);
		}
	}, [onboardingStarted, setShouldShowMessage]);
	return (
		<ConditionalWrapper
			condition={shouldShowMessage}
			wrapper={(children) => (
				<SpotlightManager blanketIsTinted={useBlanketTint}>
					<SpotlightTarget name={SPOTLIGHT_ONBOARDING_TARGET}>
						<SpotlightPulse radius={3} pulse={activeSpotlight === null}>
							{children}
							<ConversationAssistantOnboardingSpotlight
								onDismiss={onDismissMessage}
								openRovoChat={openRovoChat}
							/>
							<EngagementSpotlight engagementId={SPOTLIGHT_ONBOARDING_TARGET} />
						</SpotlightPulse>
					</SpotlightTarget>
				</SpotlightManager>
			)}
		>
			{children}
		</ConditionalWrapper>
	);
};
